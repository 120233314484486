import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Breadcrumb from '../components/breadcrumb'
import GenericPage from '../components/genericPage'

import content from '../json/accessibilityContent.json'

const AccessibilityContentPage = ({location}) => {
  return (
    <Layout simpleHeader={true} url="/demarche-accessibilite">
      <SEO 
        title="Démarche accessibilité de l’Observatoire de l'impact positif"
        description="L’Observatoire de l'impact positif des marques françaises a été développé pour répondre en partie aux préconisations d’accessibilité. Découvrez notre démarche."
        path="/demarche-accessibilite" />

      <Breadcrumb url="/demarche-accessibilite" label="Démarche accessibilité" type="credits" /> 

      <GenericPage slug="accessibilityContent" content={content} />
    </Layout>
  )
  }

export default AccessibilityContentPage

